
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        

















































































@import '@/styles/_colors.scss';

.scales {
  position: relative;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(1rem, 3rem));
  width: 100%;
  height: 6rem;
  margin-top: 1rem;
}

.bar {
  display: flex;
  flex-direction: column;
  align-self: end;
  justify-content: flex-end;
  padding-bottom: 0.15rem;
  color: var(--color-text);
  font-weight: 500;
  font-size: 0.85rem;
  text-align: center;
  text-decoration: none;
  background: $color-grey-100;
  border: 1px solid $color-grey-200;

  &:hover {
    background: $color-grey-200;
  }

  &.active {
    color: var(--color-text-secondary);
    background: var(--color-primary);
    border-color: var(--color-primary);

    &:hover {
      background: darken($color-yellow, 10%);
      border-color: darken($color-yellow, 10%);
    }
  }
}
